import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../assets/styles/Home.module.css";
import Logo from "../assets/Business Builders Logo.png";
import CreditCardIcon from "../assets/credit_card_icon.png";
import PaypalIcon from "../assets/paypal.png";
import BankIcon from "../assets/bank_icon.png";
import { loadStripe } from "@stripe/stripe-js";

function Payment() {
  const [paymentError, setPaymentError] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(true);
  const [amount, setAmount] = useState(5000); // Example amount, can be dynamic

  // Handle the payment redirect to Stripe Checkout
  const handlePaymentRedirect = async () => {
    const stripe = await loadStripe("pk_test_51QKR1qAW9rQpoCcQljuyXomkRbLWXWSCUXydzQ5ByzldxfSVvBE3Nr5a1D8xU6Zawiy9PmA47zbuwJK2Wxnd5qor00qjMsYhoU")

    const headers = {
      "Content-Type": "application/json",
    }

    const response = await fetch(`http://localhost:3000/create-checkout-session`, {
      method: "POST",
      headers:headers,
      body:JSON.stringify({ amount })
    })

    const session = await response.json();    

    const result = stripe.redirectToCheckout({
      sessionId:session.id
    });
    if(result.error){
      console.log(result.error);
    }
  }

  return (
    <div className={styles.scrollContainer}>
      <div className={styles.header}>
        <Link to="/">
          <img src={Logo} className={styles.headerLogo} alt="Business Builders Logo" />
        </Link>
        <div className={styles.headerLinkContainer}>
          <Link className={styles.headerLink} to="/">Home</Link>
          <Link className={styles.headerLink} to="/aboutus">About Us</Link>
          <Link className={styles.headerLink} to="/leadership">Leadership</Link>
          <Link className={styles.headerLink} to="/appform">Join Us</Link>
        </div>
        <a className={styles.headerIconLink} href="/">
          <span className={`material-symbols-rounded ${styles.headerIcon}`}>hub</span>
        </a>
      </div>

      <div className={styles.scrollSubcontainer}>
        <div></div>
        <div className={styles.landingSubcontainer}>
          <span className={styles.landingYear}>2024-25</span>
          <span className={styles.landingTitle}>Payment Information<br /></span>
        </div>
        <span className={`material-symbols-rounded ${styles.landingIcon}`}>arrow_downward_alt</span>
      </div>

      <div className={styles.scrollSubcontainer}>
        <div className={styles.missionSubContainer}>
          <span className={styles.missionTitle}>Choose Payment Method</span>
          <div className={styles.missionItemsContainer}>
            <div className={styles.missionItemContainer}>
              <img src={CreditCardIcon} alt="Credit Card" style={{ width: "200px", height: "200px", borderRadius: "50%" }} />
              <span className={styles.landingTitle}>Credit Card<br /></span>
              <span className={styles.missionItemDescription}>Secure payments with your credit or debit card.</span>
            </div>
            <div className={styles.missionItemContainer}>
              <img src={PaypalIcon} alt="PayPal" style={{ width: "200px", height: "200px", borderRadius: "50%" }} />
              <span className={styles.landingTitle}>PayPal<br /></span>
              <span className={styles.missionItemDescription}>Quick and easy PayPal payment option.</span>
            </div>
            <div className={styles.missionItemContainer}>
              <img src={BankIcon} alt="Bank Transfer" style={{ width: "200px", height: "200px", borderRadius: "50%" }} />
              <span className={styles.landingTitle}>Bank Transfer<br /></span>
              <span className={styles.missionItemDescription}>Transfer funds directly from your bank account.</span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.scrollSubcontainer}>
        <div className={styles.missionSubContainer}>
          <div className={styles.missionItemsContainer}>
            <button
              className={styles.hoverButton}
              onClick={handlePaymentRedirect}
            >
              Pay Now
            </button>
            {paymentError && <div className={styles.error}>{paymentError}</div>}
            {paymentSuccess && <div className={styles.success}>{paymentSuccess}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
